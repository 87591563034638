<nav *ngIf="!embed" class="navbar navbar-expand-lg navbar-dark bg-dark">
  <span class="navbar-brand">IRCnet2</span>
  <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
    <span class="navbar-toggler-icon"></span>
  </button>

  <div class="collapse navbar-collapse" id="navbarSupportedContent">
    <ul class="navbar-nav mr-auto">
      <li class="nav-item">
        <a [routerLink]="['/']" class="nav-link " [routerLinkActive]="['active']" [routerLinkActiveOptions]="{exact: true}">Home</a>
      </li>
      <li class="nav-item">
        <a [routerLink]="['/servers']" class="nav-link" [routerLinkActive]="['active']" [routerLinkActiveOptions]="{exact: true}">Servers</a>
      </li>
      <li class="nav-item">
        <a [routerLink]="['/channels']" class="nav-link" [routerLinkActive]="['active']" [routerLinkActiveOptions]="{exact: true}">Channels</a>
      </li>
    </ul>
  </div>
</nav>

<div class="container-fluid">
  <router-outlet></router-outlet>
</div>
