<div *ngIf="this.errorMessage" class="alert alert-danger" role="alert">
  {{ this.errorMessage }}
</div>

<ng-container *ngIf="!this.errorMessage">
  <div class="col">
    <form>
      <div class="row">
        <div class="col-xs">
          <select #selectInput name="filterType" [(ngModel)]="filterType" class="form-control"
                  (change)="filterTypeChanged()">
            <option *ngFor="let filterType of filterTypes" [value]="filterType">{{filterType}}</option>
          </select>
        </div>
        <div class="col">
          <input #searchTermInput name="searchTermInput" type="text" class="form-control" placeholder="Search" [(ngModel)]="searchTerm">
        </div>
      </div>
    </form>
  </div>

  <br/>

  <div class="table-responsive">
    <table datatable [dtOptions]="dtOptions" class="table table-striped table-bordered" id="datatable">
      <thead>
      <tr>
        <th>Channel</th>
        <th>Users</th>
        <th style="width: 70%;">Topic</th>
      </tr>
      </thead>
      <tbody *ngIf="channels?.length != 0">
      <tr *ngFor="let channel of channels">
        <td>{{ channel.name }}</td>
        <td>{{ channel.userCount }}</td>
        <td>{{ channel.topic | removeColors }}</td>
      </tr>
      </tbody>
      <tbody *ngIf="channels?.length == 0">
      <tr>
        <td colspan="3" class="no-data-available">No data!</td>
      </tr>
      <tbody>
    </table>
    <br>
    <br>
  </div>
</ng-container>
