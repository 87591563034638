<div *ngIf="this.errorMessage" class="alert alert-danger" role="alert">
  {{ this.errorMessage }}
</div>

<ng-container *ngIf="data.countriesWithServers">
  <div *ngFor="let country of data.countriesWithServers; let i = index">
    <br *ngIf="i != 0">
    <h2>{{ country.countryName }}</h2>
    <p>Total users: {{ country.totalUsers }}</p>

    <div class="table-responsive">
      <table class="table table-striped">
        <thead>
        <tr>
          <th scope="col" style="width: 20%;">Name</th>
          <th scope="col" style="width: 25%;">Description</th>
          <th scope="col">Users</th>
          <th scope="col">SID</th>
          <th scope="col">Version</th>
          <th>Last seen</th>
        </tr>
        </thead>

        <tbody>

        <tr *ngFor="let server of country.serverList"
            className="{{ (server.lastSeen != data.lastMapReceived) ? 'table-danger' : '' }}">
          <td>{{server.serverName}}</td>
          <td>{{server.serverInfo | correctServerDescriptionEncoding}}</td>
          <td>{{server.userCount}}</td>
          <td>{{server.sid}}</td>
          <td>{{server.version}}</td>

          <td *ngIf="server.lastSeen == data.lastMapReceived;else other_content">
            now
          </td>
          <ng-template #other_content>
            <td>{{getFormattedLastSeenTime(server.lastSeen)}} ago</td>
          </ng-template>

        </tr>
        </tbody>
      </table>
    </div>
  </div>

  <br/>

  <i>
    <small>Total servers linked: {{data.totalServers}}</small>
    <br>
    <small>Total users: {{data.totalUsers}}</small>
    <br>
    <small>Last server map received: {{getFormattedDateDifference(data.lastMapReceived)}} ago</small>
  </i>
  <br><br>
</ng-container>
