<h2>Welcome to IRCnet2!</h2>
<br/>
<p>This website offers the following services:</p>
<ul>
  <li><a [routerLink]="['/servers']">Server List</a> - lists all servers (updated every 10 seconds)</li>
  <li><a [routerLink]="['/channels']">Channel List</a> - lists all public channels (live data)</li>
</ul>

<br>
<br>
